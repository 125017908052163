.container {
  @media (max-width: "600px") {
    padding: 24px;
  }
}

.textBox {
  width: fit-content;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-top: 120px;

  @media (max-width: "900px") {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }

  .header {
    @media (max-width: "768px") {
      font-size: 24px;
    }
  }
}
